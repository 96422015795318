
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rodmap from "../assets/img/rodmap.png";
import flip from "../assets/img/flip.mp4";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg from "../assets/img/midwit_to_one_dollar.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';
import projImg1 from '../assets/img/x_logo.png';

/*

      <li>Contract audits show no warnings</li>
      */

export const Roadmap = () => {


  return (
    <section className="roadmap" id="roadmap">
    <Container>
        <h1>
      Roadmap
    </h1>

    <img src={headerImg} alt="Header Img" className="meme_img"/>
      
      </Container>
    </section>

  )
}
