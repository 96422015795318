import React from "react";
import { motion } from "framer-motion";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { About } from "./components/About";
import { Banner } from "./components/Banner";
import { Tokenomics } from "./components/Tokenomics";
import { Roadmap } from "./components/Roadmap";
import { Memes } from "./components/Memes";
import { Footer } from "./components/Footer";


export default function App() {
  return (
    <div className="App">
      <div class="rocket"></div>
      <div class="bus"></div>
      <NavBar />
      <Banner />
      <Roadmap />
      <Memes />
      <Footer />
    </div>
  );
}
